<template>
    <b-modal
      id="table-procedure-settings-modal"
      size="md"
      hide-header
      hide-footer
      centered
      no-stacking
      @hidden="onHidden"
    >
        <div class="header d-flex flex-row justify-content-between">
            <p class="title">{{ clinicHealthPlanTable?.table.name }}</p>
            <Close class="close" @click="close" />
        </div>
        <div class="content">
            <b-row v-if="clinicHealthPlanTable?.table.type === TABLE.TYPE_PROCEDURE">
                <b-col cols="12">
                    <b-form-group>
                        <label>Porte red/acresc</label>
                        <b-input-group append="%">
                            <b-form-input 
                                v-model="form.porte"
                                type="number"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback :state="validProp('porte')">
                            Valor inválido
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label>UCO red./acresc</label>
                        <b-input-group append="%">
                            <b-form-input 
                                v-model="form.uco"
                                type="number"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback :state="validProp('uco')">
                            Valor inválido
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label>M² Filme</label>
                        <b-input-group append="R$">
                            <money
                                v-model="form.m2_filme"
                                class="form-control"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback :state="validProp('m2_filme')">
                            Valor inválido
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col cols="12">
                    <b-form-group>
                        <label>Formação de preço</label>
                        <multiselect
                            label="label"
                            track-by="value"
                            v-model="form.price_formation"
                            :options="priceFormationOptions"
                            placeholder="Selecionar..."
                            :showLabels="false" 
                            class="with-border"
                        >
                        <template slot="caret">
                            <div class="chevron">
                                <ChevronDown />
                            </div>
                        </template>
                        </multiselect>
                        <b-form-invalid-feedback :state="validProp('price_formation')">
                            Campo obrigatório
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label>Inflator/Deflator</label>
                        <b-input-group append="%">
                            <b-form-input 
                                v-model="form.increase_decrease"
                                type="number"
                                :state="validProp('increase_decrease')"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback :state="validProp('increase_decrease')">
                            Valor inválido
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>

            <div class="save-button">
                <b-button
                    :disabled="!clinicHealthPlanTable || loading"
                    variant="primary"
                    @click="save"
                >
                    Salvar
                </b-button>
            </div>
        </div>
    </b-modal>
</template>
<script>
import api from '@tables/api'
import * as TABLE from '@tables/utils/constants';

export default {
    components: {
        Close: () => import('@/assets/icons/close.svg'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    },
    props: {
        clinicHealthPlanTable: Object
    },
    data() {
        return {
            TABLE,
            loading: false,
            validated: false,
            form: {},
            priceFormationOptions: [
                { label: 'Preço Fábrica (PF)', value: 'PF' }, 
                { label: 'Preço Máximo do Consumidor (PMC)', value: 'PMC' }
            ]
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('table-procedure-settings-modal')
        },
        onHidden() {
            this.validated = false
            this.form = {}
            this.$emit('hidden')
        },
        isNumber(value) {
            return typeof parseFloat(value) === 'number';
        },
        validProp(prop) {
            // eslint-disable-next-line
            if (!this.validated || this.form[prop] == 0) return null
            return !!this.form[prop] && this.form[prop] > 0
        },
        isValid() {
            this.validated = true
            if (this.clinicHealthPlanTable?.table.type === TABLE.TYPE_PROCEDURE) {
                return this.isNumber(this.form.porte) && 
                    this.isNumber(this.form.uco) && 
                    (this.isNumber(this.form.m2_filme) && this.form.m2_filme >= 0)
            } else {
                return this.form.price_formation && 
                    this.isNumber(this.form.increase_decrease)
            }
        },
        save() {
            if (!this.isValid()) return
            const payload = {
                ...this.form,
                price_formation: this.form.price_formation?.value || null,
            }
            this.loading = true
            api.updateClinicHealthPlanTable(this.clinicHealthPlanTable.id, payload)
                .then(() => {
                    this.$emit('updated', { ...this.clinicHealthPlanTable, ...payload })
                    this.$toast.success('Tabela atualizada com sucesso')
                    this.close()
                })
                .catch(err => this.$toast.error(err.message))
                .finally(() => (this.loading = false))
        }
    },
    watch: {
        clinicHealthPlanTable: {
            handler() {
                this.form = {
                    porte: this.clinicHealthPlanTable?.porte || 0,
                    uco: this.clinicHealthPlanTable?.uco || 0,
                    m2_filme: this.clinicHealthPlanTable?.m2_filme || 0,
                    price_formation: this.priceFormationOptions.find(el => el.value === this.clinicHealthPlanTable?.price_formation) || null,
                    increase_decrease: this.clinicHealthPlanTable?.increase_decrease || null
                }
            },
            immediate: true
        }
    }
}
</script>
<style lang="scss" scoped>
    .content {
        padding: 1rem;
    }
    .save-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
    }
</style>
<style lang="scss">
#table-procedure-settings-modal {
    .modal-body {
        padding: 0 !important;
        .header {
            padding: 1rem;
            border-bottom: 1px solid var(--neutral-200);
            .title {
                font-weight: 600;
                font-size: 18px;
                color: var(--type-active);
                margin: 0;
            }
            .close {
                width: 24px;
                height: 24px;
                fill: black;
                cursor: pointer;
            }
        }
    }
    .form-control {
        border-radius: 8px 0 0 8px !important;
    }
}
</style>